<template>
    <div class="principal">
        <LoaderDefault v-if="isLoading" />
        <div v-if="!isLoading" class="page-header back-image3"></div>
        <div v-if="!isLoading" class="col-md-1">
            <div class="wrapper-content bg-white pinside40">
                <div class="container-transparencia">
                    <a-col class="a-col-mb" align="center">
                        <h2 class="title-mp">
                            Nuestros Servicios</h2>
                        <div class="desc-col-new">
                            <p class="text-sub">
                                ofrecemos integralidad en servicios medio ambientales a través del desarrollo de actividades
                                que apuntan a las necesidades de cada uno de nuestros clientes. La protección del medio
                                ambiente es uno de nuestros pilares
                            </p>
                        </div>
                    </a-col>


                    <div class="inicio-servicios">
                        <div class="inicio-table">
                            <ul class="espacio-line nav nav-tabs">
                                <li id="idAcueducto" class="item-header-service active">
                                    <a @click="callMultipleMethods('acueducto')">Acueducto</a>
                                </li>
                                <li id="idAlcantarillado" class="item-header-service">
                                    <a @click="callMultipleMethods('alcantarillado')">Alcantarillado</a>
                                </li>
                                <li id="idAseo" class="item-header-service">
                                    <a @click="callMultipleMethods('aseo')">Aseo</a>
                                </li>
                            </ul>

                            <div class="tab-content ">
                                <div id="idOptionsAcueducto" class="tab-pane active">
                                    <ul class="categorias">
                                        <li v-for="(item, index) in this.listServices" :key="index"
                                            class="categoria col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <a target="_blank" rel="nofollow" :href="item.urlPdf">{{ item.name }}</a>
                                        </li>
                                    </ul>
                                    <div class="clear"></div>
                                </div>
                            </div>

                            <!-- <div class="panel-group responsive visible-xs visible-sm">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 id="idAcueducto" class="active panel-title "><a class="accordion-toggle active"
                                                data-toggle="active" data-parent="active-tabInf" href="#idOptionsAcueducto"
                                                @click="getServices('acueducto')">Acueducto<span
                                                    class="ico"></span></a>
                                        </h4>
                                    </div>
                                    <div id="idOptionsAcueducto" class="panel-collapse active" style="height: 0px;">
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title"><a class="accordion-toggle collapsed" data-toggle="collapse"
                                                data-parent="#collapse-tabInf"
                                                href="#collapse-infDocentes">Alcantarillado<span class="ico"></span></a>
                                        </h4>
                                    </div>
                                    <div id="collapse-infDocentes" class="panel-collapse collapse" style="height: 0px;">
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title"><a class="accordion-toggle collapsed" data-toggle="collapse"
                                                data-parent="#collapse-tabInf" href="#collapse-infEgresados">Aseo<span
                                                    class="ico"></span></a></h4>
                                    </div>
                                    <div id="collapse-infEgresados" class="panel-collapse collapse"></div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault'

export default {
    components: {
        LoaderDefault
    },
    data() {
        return {
            listServices: [],
            isLoading: false,
        }
    },
    mounted() {
        this.getServices("acueducto")
    },
    methods: {
        async getServices(typeServiceFilter) {
            await db.collection('services')
                .doc(typeServiceFilter)
                .get()
                .then((querySnapshot) => {
                    this.listServices = querySnapshot.data().services;
                })
        },

        actionsBtnServices() {
            const item_header_service = document.querySelectorAll('.item-header-service');
            item_header_service.forEach(item => {
                item.addEventListener('click', () => {
                    item_header_service.forEach(item => {
                        item.classList.remove('active');
                    });
                    item.classList.add('active');
                });
            });
        },

        callMultipleMethods(typeServiceFilter) {
            this.actionsBtnServices()
            this.getServices(typeServiceFilter)
        }

    },


}


</script>
<style lang="css">
.back-image3 {
    background: url('../../../assets/img/fotoServicio.png') no-repeat center;
    background-size: cover;


}

/* titulo servicio */

section {
    display: block;
}

.vc_col-sm-12 {

    position: relative;
    min-height: 1px;
    box-sizing: border-box;
}

.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.vc_custom_1566344789426 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.wpb_wrapper h1 {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -1px;
    font-weight: 900;
    color: var(--colorGreenPrimary);
    margin: 0px 0px 10px 0px;
    font-family: 'helveticablackcondensed';
}



.inicio-servicios {
    padding: 50px 0;
}

.inicio-table {
    margin-bottom: 40px;
}

.nav-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.item-header-service {
    width: 33.3%;
}

ul {
    padding: 0;
    margin-top: 0;
    list-style: none;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav>li {
    position: relative;
    display: block;
}

.inicio-table .nav-tabs li.active a {
    color: var(--colorGreenPrimary);
    background-color: #F3F3F3;
    border: none;
    border-bottom: 1px solid #f3f3f3;
}

.inicio-table .nav-tabs li a {
    color: #999999;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.nav-tabs>li.active>a {
    cursor: default;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #eee;
}

a {
    text-decoration: none;
    background: transparent;
}

/* mostrar informacion de la tabla */

.tab-pane {

    background-color: #F3F3F3;
    padding: 5px 10px;

}

.tab-content>.active {
    display: block;
}

.inicio-table .tab-content .tab-pane .categorias {
    margin: 0;
}

.inicio-table .tab-content .tab-pane .categorias .categoria {
    margin-top: 6px;
    margin-bottom: 6px;
}



.col-md-4,
.col-lg-4,
.col-sm-6,
.col-xs-12 {

    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.inicio-table .tab-content .tab-pane .categorias .categoria a:link,
.inicio-table .tab-content .tab-pane .categorias .categoria a:visited {
    color: #414151;
    font-size: 15px;
    font-family: 'museo700', sans-serif;
    width: 100%;
    display: block;
    padding: 20px 30px 20px 15px;
    background: #FFFFFF url('../../../assets/img/ico-flecha-azul.png') no-repeat 95% 50%;
    margin: auto;
    border-radius: 5px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

a {
    text-decoration: none;
}



.tab-content {
    padding: 0%
}

.clear {
    clear: both;
}

.subtittle {
    font-size: 25px;
    font-weight: 400;

}

.parrafo {
    font-size: 25px;
    font-weight: 400;
}

.espacio-line:after {
    clear: both;
    display: table;
    content: " ";
}


/* estyle con su responsi */

.visible-xs,
.visible-sm {
    display: none !important;
}

.panel-group {
    margin-bottom: 20px;
}

.panel-group .panel {
    border: none;
    margin-bottom: 10px;
    border-radius: 4px;
}

.panel {
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    background-color: #fff;
}

.panel-group .panel .panel-heading {
    background: #F3F3F3;
    padding: 0;
    border-bottom: 0;
}

.panel-default>.panel-heading {
    color: #333;
    border-color: #ddd;
}

.panel-heading {

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    font-weight: 500;
    line-height: 1.1;
}

.panel-group .panel .panel-heading .panel-title a {
    color: #999999;
}

.panel-group .panel .panel-heading .panel-title a {
    padding: 30px 80px 30px 30px;
    display: block;
    color: #00478D;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
}

.panel-group .panel .panel-heading .panel-title a.ico {
    background-position: 0 -56px;
}

.panel-group .panel .panel-heading .panel-title a .ico {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -28px;
    right: 2%;
    width: 56px;
    height: 56px;
    background: url('../../../assets/img/ico-toggle-tab.png') 0 0 no-repeat;
}

.collapse {
    display: none;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

@media (max-width: 500px) {
    .item-header-service {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .col-lg-4 {
        width: 33.33333333%;
        float: left;
    }

    .col-sm-6:nth-child(2n+1) {
        clear: none;
    }

    .col-lg-4:nth-child(3n+1) {
        clear: left;
    }
}

@media (min-width: 768px) {
    .wpb_wrapper h1 {
        font-size: 43px;
    }

    .vc_col-sm-12 {
        width: 100%;
        float: left;
    }
}
</style>